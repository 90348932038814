
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import router from './router'
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs/index.js'

import * as Sentry from "@sentry/vue";
Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_KEY,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/convertlabs\.io/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


import mixin from './mixins'
Vue.mixin(mixin);


import 'flex.box/src/flexbox.css'


import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);


import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueSession from "vue-session"
import autowidth from "../directives/autowidth";

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/display.css';

import Vuebar from 'vuebar'

/* MDI Icons

 In order to use different/new icons need to be imported BELLOW mdi{IconName}
 this is done to improve tree shaking and avoid unused icons to be bundled

 */

import VueMdijs from 'vue-mdijs'
import {
    mdiHistory,
    mdiViewDashboard,
    mdiBell,
    mdiBellOutline,
    mdiMenu,
    mdiAccount,
    mdiPhone,
    mdiEmail,
    mdiMapMarker,
    mdiMagnify,
    mdiPencilOutline,
    mdiDotsVertical,
    mdiContentDuplicate,
    mdiCashCheck,
    mdiEmailSendOutline,
    mdiCalendarMonth,
    mdiCircle,
    mdiLockReset,
    mdiTrashCanOutline,
    mdiCurrencyUsd,
    mdiAlertCircleOutline,
    mdiCheck,
    mdiClose,
    mdiInstagram,
    mdiTwitter,
    mdiPinterest,
    mdiLinkedin,
    mdiYoutube,
    mdiFacebook,
    mdiFileDocument,
    mdiFileDocumentOutline,
    mdiCardAccountDetails,
    mdiCheckboxMultipleMarked,
    mdiCalendarAccount,
    mdiFinance,
    mdiWeb,
    mdiBriefcasePlusOutline,
    mdiLogout,
    mdiAccountOutline,
    mdiFormatListCheckbox,
    mdiAccountGroup,
    mdiAt,
    mdiHelpCircleOutline,
    mdiFormatListBulleted,
    mdiContentCopy,
    mdiAccountCheckOutline,
    mdiAccountCancelOutline,
    mdiArrowUp,
    mdiArrowDown,
    mdiMinus,
    mdiChartBox,
    mdiTrello,
    mdiViewDashboardVariant,
    mdiFormatListText,
    mdiOpenInNew,
    mdiAccountPlus,
    mdiNotePlus,
    mdiDownload,
    mdiAccountMultiplePlus,
    mdiCreditCardPlus,
    mdiFlash,
    mdiCircleOutline,
    mdiBlockHelper,
    mdiStar,
    mdiBookArrowRight,
    mdiCreditCardOutline,
    mdiCog,
    mdiLightbulbOnOutline,
    mdiRotate360,

} from '@mdi/js';
//
VueMdijs.add({
    mdiHistory,
    mdiViewDashboard,
    mdiBell,
    mdiBellOutline,
    mdiMenu,
    mdiAccount,
    mdiPhone,
    mdiEmail,
    mdiMapMarker,
    mdiMagnify,
    mdiPencilOutline,
    mdiDotsVertical,
    mdiContentDuplicate,
    mdiCashCheck,
    mdiEmailSendOutline,
    mdiCalendarMonth,
    mdiCircle,
    mdiLockReset,
    mdiTrashCanOutline,
    mdiCurrencyUsd,
    mdiAlertCircleOutline,
    mdiCheck,
    mdiClose,
    mdiInstagram,
    mdiTwitter,
    mdiPinterest,
    mdiLinkedin,
    mdiYoutube,
    mdiFacebook,
    mdiFileDocument,
    mdiFileDocumentOutline,
    mdiCardAccountDetails,
    mdiCheckboxMultipleMarked,
    mdiCalendarAccount,
    mdiFinance,
    mdiWeb,
    mdiBriefcasePlusOutline,
    mdiLogout,
    mdiAccountOutline,
    mdiFormatListCheckbox,
    mdiAccountGroup,
    mdiAt,
    mdiHelpCircleOutline,
    mdiFormatListBulleted,
    mdiContentCopy,
    mdiAccountCheckOutline,
    mdiAccountCancelOutline,
    mdiArrowUp,
    mdiArrowDown,
    mdiMinus,
    mdiChartBox,
    mdiTrello,
    mdiViewDashboardVariant,
    mdiFormatListText,
    mdiOpenInNew,
    mdiAccountPlus,
    mdiNotePlus,
    mdiDownload,
    mdiAccountMultiplePlus,
    mdiCreditCardPlus,
    mdiFlash,
    mdiCircleOutline,
    mdiBlockHelper,
    mdiStar,
    mdiBookArrowRight,
    mdiCreditCardOutline,
    mdiCog,
    mdiLightbulbOnOutline,
    mdiRotate360,
})
Vue.use(VueMdijs)


/* Local Components */
const  Dashboard = () => import(/* webpackChunkName: "dash-v2" */ './index.vue')

import icon from '@components/c-icon/index.vue'

Vue.component('c-icon', icon)

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(ElementUI, { locale })
Vue.use(VueMoment, { moment})
Vue.use(VueSession)
Vue.use(Vuebar)
Vue.use(LaravelPermissionToVueJS)


Vue.component('vue-scroll', VuePerfectScrollbar)

// Directive to autoresize el-select elements
Vue.directive('autowidth', autowidth)

// @TODO REPLACE ALL TO FIXED FOR TOMONEY
Vue.filter('toFixed', function (value) {

    var numberValue = Number(value);

    if (!numberValue) numberValue = 0
    numberValue = numberValue.toFixed(2)
    return numberValue
})

// @TODO CHANGE FOR ToMoney FILTER CREATED BY DANIEL
Vue.filter('formatMoney', function (value) {

    var numberValue = Number(value);

    if (!numberValue) numberValue = 0
    numberValue = parseFloat(numberValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return numberValue
})

// Format Money leaderboard (K)
Vue.filter('formatMoneyInK', function (value) {

    var numberValue = Number(value);

    if(isNaN(numberValue))
        return 0;

    if (numberValue >= 1000) {
        return (numberValue/1000).toFixed(1) + "k";
    } else {
        return numberValue.toFixed();
    }
})



Vue.config.productionTip = false

// @TODO THIS NEEDS TO BE DONE ANOTHER WAY
router.beforeEach((to, from, next) => {
    axios.defaults.baseURL = (to.matched[0].path === '/domain') ? `/api/multi-tenant` : '/api'
    next()
})


import { Notification } from 'element-ui';

/* Force redirection to login when backend returns http 401 */
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

axios.interceptors.response.use( function (response) {


        /* responsible of enabling the canceled subscription message */
        if(response.data.canceledAccount){
            Vue.prototype.$subscription.active =  false
        }else{
            Vue.prototype.$subscription.active =  true
        }


        return response

}, function (error) {

    // If the request returned an HTTP code
    if(error.response){

        if (error.response.status === 401) {
            // router.push('/login').catch(() => {})

            return Promise.reject(error).finally(() => {
                document.location = "/login";
            })

        }
        else if (error.response.status === 403) {

            Notification.error({
                title: 'Error',
                message: 'Your account does not have enough permissions',
                offset: 100
            });

        }
    }

    return Promise.reject(error)

})



document.addEventListener('DOMContentLoaded', () => {

const instanceElement = document.getElementById('app')

Vue.prototype.$clientId  = instanceElement.dataset.clientId
Vue.prototype.$stripeKey = instanceElement.dataset.stripeKey
Vue.prototype.$serviceCurrency = JSON.parse(instanceElement.dataset.serviceCurrency)
Vue.prototype.$guardModel = "member"
Vue.prototype.$subscription =  Vue.observable({ id:0, active: true, type:'normal' })
Vue.prototype.$isOnboardingCompleted = Vue.observable(!!window.is_onboarding_completed);

new Vue({
    el: '#app',
    router,
    render: h => h( Dashboard ,{})
})

// if (module.hot) {
//     module.hot.accept()
// }

})

