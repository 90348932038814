export default {


    /*
    * Directive to autoresize el-inputs to its value size
    *
    * */

    bind(el){

        // this method adds shadow span to input
        // we'll use this span to calculate text width
        const  input = el.querySelector('.el-input__inner')
        const span = document.createElement('span')

        span.classList.add('resizeable-shadow');
        input.parentNode.insertBefore(span, input);

    },

    componentUpdated (el, binding, vnode) {

        vnode.context.$nextTick(() => {

            const input = el.querySelector('.el-input__inner')
            const span = input.previousSibling

            span.textContent = input.value;

            // 50 is the padding of the inner input of the select
            input.style.width = `${span.getBoundingClientRect().width + 50}px`;
        });

    },

}
