/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
  					path => router path
  					name => router name
  					component(lazy loading) => component to load
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import MultitenantRoutes from '@multitenant/router'


Vue.use(Router)

// This is to avoid creating 'base' components to only include <router-view>
const RouterView = {
    // Inline declaration of a component that renders our <router-view>
    render: (c) => c('router-view', {
        class: 'view'
    })
};

const router = new Router({
  	mode: 'history',
	base: '/',
	routes: [

        // =============================================================================
        // Booking Routes
        // =============================================================================
        {
            path: '/',
            name: 'dashboard',
            component: RouterView,
            children: [

				// =============================================================================
                // Onboarding
                // =============================================================================
                {
                    path: 'get-started',
                    name: 'onboard',
                    meta: { label: "Getting Started" },
                    component: () => import(/* webpackChunkName: "onboarding" */ './onboarding/index.vue'),
					beforeEnter: (to, from, next) => {
						// reject the navigation
						if (!is_onboarding_completed && memberID >= 1) {
							next()
						} else {
							next({
								name: 'dashboard'
							});
						}
					},
                },


                // =============================================================================
                // Main Dashboard Route
                // =============================================================================
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    meta: { label: "Dashboard" },
                    component: () => import(/* webpackChunkName: "dashboard" */ './dashboard.vue'),

                },
                // =============================================================================
                // Settings Routes
                // =============================================================================
                {
                    path: 'billing',
                    name: 'billing',
                    meta: { label: "Billing" },
                    component: () => import(/* webpackChunkName: "billing" */ './billing.vue'),

                },
                {
                    path: 'settings',
                    name: 'settings',
                    meta: { label: "Settings" },
                    component: () => import(/* webpackChunkName: "settings" */ './settings.vue'),
                }
            ]

        },

		// =============================================================================
		// Booking Routes
		// =============================================================================
		{
			path: '/booking',
            component: RouterView,
			children: [
				// =============================================================================
				// PAGES
				// =============================================================================
                {
                    path: 'bookings',
                    component: RouterView,
                    meta: { label: "Bookings" },
                    children: [
                        {
                            path: '',
                            name: 'bookings',
                            component: () => import('./booking/bookings/List.vue'),
                        },
                        {
                            path: 'add',
                            name: 'addBooking',

                            component: () => import('../bookingForm/BookingForm.vue'),
                            props: (route) => ({
                                clientId: Vue.prototype.$clientId,
                                stripeKey: Vue.prototype.$stripeKey,

                                /* Other props sent via router push params */
                                ...route.params
                            })

                        },
                        {
                            path: 'edit/:id',
                            name: 'editBooking',
                            component: () => import('./booking/bookings/Edit.vue'),
                        },
                        {
                            path: ':filter',
                            name: 'bookingsUnassigned',
                            component: () => import('./booking/bookings/List.vue'),
                        },
                    ]
                },

				{
					path: 'stripe',
					name: 'stripe',
					meta: { label: 'Stripe'},
					component: () => import( './booking/integrations/components/Stripe.vue'),
				},

				{
					path: 'gigwage',
					name: 'gigwage',
					meta: { label: 'Gig Wage'},
					component: () => import( './booking/integrations/components/Gigwage.vue'),
				},

				{
					path: 'nicejob',
					name: 'nicejob',
					meta: { label: 'Nicejob'},
					component: () => import( './booking/integrations/components/Nicejob.vue'),
				},

                {
                    path: 'calendar',
                    name: 'calendar',
                    meta: { label: "Calendar" },
                    component: () => import('./booking/calendar/calendar.vue'),
                },

                {
                    path: 'livestream',
                    name: 'livestream',
                    meta: { label: "Live Stream" },
                    component: () => import('./booking/livestream/index.vue'),
                },

                {
					path: 'customer',
                    component: RouterView,
                    meta: { label: "Customers" },
                    children: [
						{
							path: '/',
							name: 'listCustomer',
							component: () => import('./booking/customers/List.vue'),
						},
						{
							path: 'view/:id',
							name: 'viewCustomer',
							component: () => import('./booking/customers/Index.vue'),
						},
					]
				},
				{
					path: 'terms',
					name: 'Terms',
					component: () => import(/* webpackChunkName: "bookingTerms" */'./booking/settings/Settings.vue'),
				},
				{
					path: 'reports',
                    component: RouterView,
                    meta: { label: "Payouts" },
                    children: [
						{
							path: '/',
							name: 'reports',
							component: () => import('./booking/reports/Reports.vue'),
						},
						{
							path: 'team/:id',
							name: 'teamReport',
							component: () => import('./booking/reports/Team.vue'),
						}
					],
				},
				{
					path: 'history',
					name: 'history',
					meta: { label: "Payments History" },
					component: () => import('./booking/reports/PayoutsHistory.vue'),
				},
				// {
				// 	path: 'future-payments',
				// 	name: 'future-payments',
				// 	meta: { label: "Future Payments" },
				// 	component: () => import('./booking/reports/FuturePayments.vue'),
				// },
				{
					path: 'customer-portal',
					name: 'customer-portal',
                    meta: { label: "Customer Portal" },

                    component: () => import(/* webpackChunkName: "bookingSettings" */'./booking/CustomerPortal.vue'),
				},
				// {
				// 	path: 'tracking',
				// 	name: 'tracking',
                //     meta: { label: "Conversion Tracking" },
                //
                //     component: () => import('./booking/analytics/ThankYouSettings.vue'),
				// },
				{
					path: 'services-list',
					name: 'services-list',
					meta: { label: "Services" },
					component: () => import('./booking/Services.vue'),
				},
				{
					path: 'integrations',
					name: 'integrations',
					meta: { label: "Integrations" },
					component: () => import('./booking/integrations/Integrations.vue'),
				},
				{
					path: 'settings',
					name: 'booking-settings',
                    meta: { label: "Payment Settings" },

                    component: () => import(/* webpackChunkName: "bookingSettings" */'./booking/Settings.vue'),
				},
				{
					path: 'time',
					name: 'time-scheduling',
                    meta: { label: "Time & Scheduling" },

                    component: () => import(/* webpackChunkName: "bookingTimeSettings" */'./booking/Time.vue'),
				},
				{
					path: 'services',
                    component: RouterView,
                    meta: { label: "Services" },

                    children: [
						{
							path: '',
							name: 'services',
							component: () => import('./booking/services/List.vue'),
						},
						{
							path: 'add',
							name: 'addService',
							component: () => import('./booking/services/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editService',
							component: () => import('./booking/services/Edit.vue'),
						},
					]
				},

				{
					path: 'categories',
                    component: RouterView,
                    meta: { label: "Categories" },

                    children: [
						{
							path: '',
							name: 'categories',
							component: () => import('./booking/categories/List.vue'),
						},
						{
							path: 'add',
							name: 'addCategory',
							component: () => import('./booking/categories/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editCategory',
							component: () => import('./booking/categories/Edit.vue'),
						},
					]
				},
				{
					path: 'pricing',
                    component: RouterView,
                    meta: { label: "Pricing Parameters" },

                    children: [
						{
							path: '',
							name: 'pricing',
							component: () => import('./booking/pricing/List.vue'),
						},
						{
							path: 'add',
							name: 'addPrice',
							component: () => import('./booking/pricing/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editPrice',
							component: () => import('./booking/pricing/Edit.vue'),
						},
					]
				},
				{
					path: 'extras',
                    component: RouterView,
                    meta: { label: "Extras" },

                    children: [
						{
							path: '',
							name: 'extras',
							component: () => import('./booking/extras/List.vue'),
						},
						{
							path: 'add',
							name: 'addExtra',
							component: () => import('./booking/extras/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editExtra',
							component: () => import('./booking/extras/Edit.vue'),
						},
					]
				},
				{
					path: 'quotes',
                    component: RouterView,
                    meta: { label: "Quotes" },
                    children: [
						{
							path: '/',
							name: 'quotelist',
							component: () => import('./booking/quotes/List.vue'),
						}
					]
				},
				{
					path: 'invoices',
                    component: RouterView,
                    meta: { label: "Invoices" },
                    children: [
						{
							path: '',
							name: 'list',
							component: () => import('./booking/invoice/List.vue'),
						}
					]
				},

                {
                    path: 'teams',
                    component: RouterView,
                    meta: { label: "Service Providers" },
                    children: [
                        {
                            path: '',
                            name: 'teams',
                            component: () => import('./booking/teams/List.vue'),
                        },
                        {
                            path: 'add',
                            name: 'addTeam',
                            component: () => import('./booking/teams/Add.vue'),
                        },
                        {
                            path: 'edit/:id',
                            name: 'editTeam',
                            component: () => import('./booking/teams/Base.vue'),
                        }
                    ]
                },


				{
					path: 'notifications',
                    component: RouterView,
                    meta: { label: "Notifications" },
                    children: [
						{
							path: '',
							name: 'NotificationDetails',
							component: () => import('./booking/notifications/Notifications.vue'),
						},

					]
				},
				{
					path: 'gift_cards',
                    component: RouterView,
                    meta: { label: "Gift Cards" },
                    children: [
						{
							path: '',
							name: 'giftcardlist',
							component: () => import('./booking/giftcards/List.vue'),
						},

					]
				},
				{
					path: 'frequencies',
                    component: RouterView,
                    meta: { label: "Frequencies" },

                    children: [
						{
							path: '',
							name: 'frequencies',
							component: () => import('./booking/frequencies/List.vue'),
						},
						{
							path: 'add',
							name: 'addFrequency',
							component: () => import('./booking/frequencies/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editFrequency',
							component: () => import('./booking/frequencies/Edit.vue'),
						},
					]
				},
				{
					path: 'discounts',
                    component: RouterView,
                    meta: { label: "Discounts" },
                    children: [
						{
							path: '',
							name: 'discounts',
							component: () => import('./booking/discounts/List.vue'),
						},
						{
							path: 'add',
							name: 'addDiscount',
							component: () => import('./booking/discounts/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editDiscount',
							component: () => import('./booking/discounts/Edit.vue'),
						},
					]
				},
				{
					path: 'qa-section',
					name: 'qa-section',
                    meta: { label: "QA" },

                    component: () => import(/* webpackChunkName: "bookingQA" */ './booking/Qalist.vue'),
				},
				{
					path: 'forms',
					name: 'forms',
                    meta: { label: "Forms Customize" },
                    component: () => import(/* webpackChunkName: "bookingWidget" */ './booking/book_settings/index.vue'),
				}
			]
		},


        // =============================================================================
        // Marketing Routes
        // =============================================================================
        {
            path: '/marketing',
            component: RouterView,
            meta: { label: "Marketing" },

            children: [
                {
                    path: '',
                    name: 'marketing',
                    component: () => import('./marketing/index.vue'),
                },
                {
                    path: 'leads',
                    name: 'leads',
                    meta: { label: "Leads" },
                    component: () => import('./marketing/leads.vue'),
                },
                {
                    path: 'analytics',
                    name: 'editBooking',
                    component: () => import('./marketing/analytics.vue'),
                },
                {
                    path: 'campaign',
                    component: RouterView,
                    children: [
                        {
                            path: 'new',
                            name: 'Campaign',
                            meta: { label: "Marketing" },
                            component: () => import('./marketing/email/new.vue'),
                        },
                        {
                            path: 'scheduled',
                            name: 'Scheduled',
                            component: () => import('./marketing/email/scheduled.vue'),
                        },
                        {
                            path: 'widgets',
                            name: 'widgets',
                            component: () => import('./marketing/email/widgets.vue'),
                        },
                    ]
                },
                {
                    path: 'widgets',
                    component: RouterView,
                    meta: { label: "Widgets" },
                    children: [
                        {
                            path: 'new',
                            name: 'Campaign',
                            meta: { label: "Campaign" },
                            component: () => import('./marketing/widgets/new.vue'),
                        },
                        {
                            path: 'list',
                            name: 'Scheduled',
                            meta: { label: "Widgets" },
                            component: () => import('./marketing/widgets/list.vue'),
                        }
                    ]
                },

            ]
        },

        // =============================================================================
        // Multitenant Routes
        // =============================================================================
        MultitenantRoutes,


		// Redirect to 404 page, if no match found
		{
			path: '*',
			redirect: { name: 'dashboard'}
		}
	],

})




export default router
